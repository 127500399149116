import React, { useEffect, useState } from "react"
import { useLocation } from "@reach/router"
import queryString from "query-string"
import { useStaticQuery, graphql } from "gatsby"
import "bootstrap/dist/css/bootstrap.min.css"
import SiteMetadata from "../componets/SiteMetadata/SiteMetadata"
import Header from "../componets/Header/Header"
import Footer from "../componets/Footer/Footer"
import ResultatRecherche from "../componets/ResultatRecherche/ResultatRecherche"
import Breadcrumbs from "../componets/Breadcrumbs/Breadcrumbs"
import {
  stripTags,
  translateRegion,
  translateCity,
  translateSector,
  translateType,
  translateLevel,
  translatePublicAdmis,
  translateMobilizableDevice,
  translateTraining,
  translateAgency,
  translateSponsoredTraining,
} from "../services/helpers"
import "./globals.css"

export default function Home(props) {
  const [allMetiersVises, setAllMetiersVises] = useState([])
  const location = useLocation()
  const queries = queryString.parse(location.search)
  const data = useStaticQuery(graphql`
    {
      hasura {
        seformer_db_region(order_by: { name: asc }) {
          region_id
          name
          db_country {
            country_id
            name
          }
        }
        seformer_db_city(order_by: { name: asc }) {
          city_id
          name
          db_region {
            region_id
            name
          }
        }
        seformer_db_sector(order_by: { name: asc }) {
          sector_id
          name
          db_media {
            link_full
          }
        }
        seformer_db_training_type(order_by: { name: asc }) {
          type_id
          name
        }
        seformer_db_level(order_by: { name: asc }) {
          level_id
          name
          image {
            link_full
          }
        }
        seformer_db_public_admis {
          public_admis_id
          name
        }
        seformer_db_mobilization_device {
          mobilization_device_id
          name
        }
        seformer_db_article(
          where: { status: { _eq: "active" } }
          order_by: { date_create: desc, date_update: desc }
        ) {
          article_id
          reference
          title
          contact_recrutment_email
          db_training_article {
            goals
            programm
            job_targeted
            training_hours
            training_days
            training_months
            success_rate
            db_city {
              city_id
              name
              postal_code
              db_region {
                region_id
                name
              }
            }
            db_training_article_public_admis {
              db_public_admi {
                public_admis_id
                name
              }
            }
            db_training_article_mobilization_devices {
              db_mobilization_device {
                mobilization_device_id
                name
              }
            }
            db_degree {
              degree_id
              name
            }
          }
          db_training_article {
            db_city {
              city_id
              name
              db_region {
                region_id
                name
              }
            }
          }
          db_article_option_paids(where: { is_active: { _eq: true } }) {
            db_option {
              name
              option_id
              duration
              db_option_type {
                value
              }
            }
            db_payement {
              date_payement
            }
          }
          db_sector {
            sector_id
            name
          }
          db_level {
            level_id
            name
          }
          db_training_article_types {
            db_training_type {
              type_id
              name
            }
          }
          info_agency
          db_agency {
            agency_id
            name
            db_public_info_agency {
              agency_description
              website
              dbMediaByMainLogoId {
                link_full
              }
              db_address {
                address
                additional_address
                db_city {
                  name
                  db_region {
                    name
                  }
                }
              }
            }
          }
        }
        seformer_db_agency {
          agency_id
          name
          db_public_info_agency {
            dbMediaByMainLogoId {
              link_full
            }
          }
        }
      }
      logo: file(
        absolutePath: {
          regex: "/images/logo_seformer-f59ecb37be2dc067b743d7ee0a113952.webp/"
        }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      strapiSearchPage {
        short_description {
          description
        }
      }
    }
  `)

  const metas = {
    title:
      "Annonces formation, alternance et stage à La Réunion, Drom Com, Océan Indien",
    metatitle:
      "Offres de formation à La Réunion, dans les Drom Com, l'Océan Indien - Se Former.re",
    description:
      "Toutes les annonces de formation et offre de stage, alternance dans tous les domaines, secteurs et pour tous les niveaux.",
    keywords:
      "formation, stage, La Réunion, drom, com, annonce, formation à distance, formation en continu, professionnelle, en alternance, Mayotte, Maurice, Océan Indien, Antilles, Madagascar",
  }

  const regions = data.hasura.seformer_db_region.map(item =>
    translateRegion(item)
  )
  const cities = data.hasura.seformer_db_city.map(item => translateCity(item))
  const sectors = data.hasura.seformer_db_sector.map(item =>
    translateSector(item)
  )
  const types = data.hasura.seformer_db_training_type.map(item =>
    translateType(item)
  )
  const levels = data.hasura.seformer_db_level.map(item => translateLevel(item))
  const publicAdmis = data.hasura.seformer_db_public_admis.map(item =>
    translatePublicAdmis(item)
  )
  const mobilizableDevices = data.hasura.seformer_db_mobilization_device.map(
    item => translateMobilizableDevice(item)
  )
  const trainings = data.hasura.seformer_db_article.map(item =>
    translateTraining(item)
  )
  const sponsoredTrainings = trainings.filter(item => {
    const db_option =
      (item.db_article_option_paids.length &&
        item.db_article_option_paids[0].db_option) ||
      null
    return db_option && db_option.db_option_type.value === "sponsorised"
  })
  const agencies = data.hasura.seformer_db_agency.map(item =>
    translateAgency(item)
  )
  
  useEffect(() => {
    if (!allMetiersVises.length) {
      const allSelectMetiersVises = []
      if (trainings.length) {
        trainings.map(item => {
          if (
            item.target_job &&
            allSelectMetiersVises.filter(
              e => e.name === stripTags(item.target_job)
            ).length == 0
          ) {
            allSelectMetiersVises.push({
              id: item.id,
              name: stripTags(item.target_job),
            })
          }
        })
      }
      setAllMetiersVises(allSelectMetiersVises)
    }
  }, [allMetiersVises])

  return (
    <div className="pageresultatrecherche">
      <SiteMetadata metas={metas} />
      <Header {...props} logo={data.logo} />
      <Breadcrumbs />
      <ResultatRecherche
        currentRegion={""}
        queries={queries}
        agencies={agencies}
        trainings={trainings}
        sponsoredTrainings={sponsoredTrainings}
        types={types}
        regions={regions}
        cities={cities}
        levels={levels}
        sectors={sectors}
        publicAdmis={publicAdmis}
        mobilizableDevices={mobilizableDevices}
        shortDescription={data.strapiSearchPage.short_description}
        allMetiersVises={allMetiersVises}
      />
      <Footer />
    </div>
  )
}
